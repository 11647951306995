<template>
  <div v-if="!channelData.banned" class="channel__page">
    <div class="row channel__column">
      <!---poster="https://us-central-1-s3.netdepot.com/brime/ShareX/2021/08/brime_loading.gif" --->
        <video
          id="stream-video"
          ref="plyr"
          playsinline
          :poster="this.live ? 'https://assets.brimecdn.com/stream_loading.gif' : this.offline_image"
        ></video>
    </div>
    <div class="transcript" id="localTranscript"></div>
    <div class="channelData">
      <b-avatar
        v-if="channelData.owner.xid"
        size="50"
        :src="`https://assets.brimecdn.com/cdn-cgi/image/width=80,quality=100/users/${channelData.owner.xid}/avatar`"
        variant="light-primary"
        class="badge-minimal hide-on-mobile"
        style="margin-top: 20px"
      />
      <span style="vertical-align: middle;">
      <span
        style="vertical-align: middle; color: white; font-size: 16px;"
        class="channelName"
        id="channelName"
      >
        <img
          v-if="channelData.verified"
          src="https://beta.brimelive.com/brime_verified.png"
          style="padding-left: 5px"
        />
        {{ channelData.display_name }}</span
      >
      <span style="opacity: 0.5;">
        | <span
          id="viewcount"
          style="font-size: 12px !important;"
          class="viewcount"
        /><feather-icon
        icon="EyeIcon"
        class="mr-30"
        style="color: #b4b7bd !important;"
      />
        {{ viewerCount }} </span
      > <!---{{ countTerm }}-->
      <span class="streamCategory">
        <router-link :to="`/category/${channelData.stream.category.slug}`">{{
          channelData.stream.category.name
        }}</router-link>
        &bull;
        <span class="streamTitle">
          {{ channelData.stream.title }}
        </span>
      </span>
    </span>
      <b-button-group size="sm" class="float-right actionButtons"
        ><b-button
          v-if="
            userChannel &&
            channelData.stream.live &&
            userChannel.stream.live &&
            channelData.xid !== userChannel.xid
          "
          title="Raid Channel"
          variant="primary"
          @click="openRaidModal"
          size="sm"
          class="mr-1 subscribe"
        >
          Raid
        </b-button>
        <b-modal
          ref="raidModal"
          id="raidModal"
          title="Confirm Raid"
          ok-title="Send Raid"
          ok-button
          centered
          button-size="sm"
          @ok="sendRaid()"
        >
         <div class="text-center" v-if="raidCount == 0">
    <b-spinner variant="primary" label="Text Centered" /> <h3 class="mt-1">Loading Raid Details</h3>
  </div>
          <b-card-text v-if="raidCount !== 0">
          <div class="text-center">
            <h5>
              You are about to raid
              <span style="color: #7367f0; font-weight: bold">{{
                channelData.display_name
              }}</span>
              with {{ raidCount }} viewers
            </h5></div>
          </b-card-text>
        </b-modal>
        <b-button
          v-if="monetized & !isSubscribed"
          variant="primary"
          size="sm"
          class="mr-1 subscribe"
          @click="subscribe"
        >
          Subscribe
        </b-button>
        <b-button
          v-if="monetized & isSubscribed"
          variant="outline-primary"
          size="sm"
          class="mr-1 subscribe"
          disabled
        >
          Subscribed
        </b-button>
        <b-modal
          id="modal-lg"
          ref="clip-modal"
          title-sr-only
          centered
          size="lg"
          title="Large Modal"
          :hide-footer="true"
          @hide="clipModalHideEvent"
        >
          <clip-module :stream="channelData.xid"></clip-module>
        </b-modal>
        <b-button
          v-if="following === false && !isChannelOwner"
          variant="outline-primary"
          class="newFollowButton"
          size="sm"
          @click="follow"
        >
          <feather-icon icon="HeartIcon" class="mr-50 pt-0 pb-0" />
          <span class="align-middle" style="line-height: 14px">Follow</span>
        </b-button>
        <b-button
          v-if="following === true && !isChannelOwner"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          title="following"
          variant="primary"
          class="btn-icon"
          @click="unfollow"
        >
          <feather-icon icon="HeartIcon" class="align-middle"/> <span class="align-middle">Following</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="followCount mr-1"
          disabled
        >
          <span id="followCount">{{ followers }} </span>
          <span id="followersText">Followers</span>
        </b-button>
        <b-button
          v-if="this.streamlabs_donate"
          variant="outline-primary"
          size="sm"
          class="subscribe"
          @click="donate('streamlabs')"
          :title="`Support ${channelData.slug}`"
        >
          Tip
        </b-button>
        <b-button
          v-if="donationIntegration.platform"
          variant="outline-primary"
          size="sm"
          class="subscribe"
          @click="donate(donationIntegration.platform)"
          :title="`Support ${channelData.slug}`"
        >
          Tip
        </b-button>
      </b-button-group>
      <div id="tabSection" class="tabSection hide-on-mobile mt-1">
        <b-tabs>
          <b-tab title="Socials">
            <b-card-text>
              <section id="card-images" class="vodSection">
                <b-row>
                  <h4 style="margin-left: 15px; font-size: 14px">
                    <feather-icon icon="AlertCircleIcon" size="24" /> This
                    channel doesn't currently have any socials added.
                  </h4>
                  <br /><br /><br />
                </b-row>
              </section>
            </b-card-text>
          </b-tab>
          <b-tab active title="Clips">
            <b-card-text>
              <section id="card-images" class="vodSection">
                <b-row>
                  <b-col
                    v-for="item in channelClips"
                    :key="item.xid"
                    md="5"
                    lg="4"
                    xl="3"
                    ><b-card
                      v-b-modal.clipPlayerModal
                      class="mb-3"
                      @click="openClip(item.xid)"
                    >
                      <div class="vod__image">
                        <progressive-img
                          v-b-popover.hover.bottom="''"
                          :src="`https://clips.brimecdn.com/${channelData.xid}/clips/${item.xid}.jpg`"
                          fallback="https://f004.backblazeb2.com/file/brime-assets/missing_thumbnail.png"
                        />
                        <div class="vod__duration">
                          {{ getDurationClip(item.duration) }}
                        </div>
                      </div>
                      <div id="vodTitle" class="vod__title">
                        {{ item.title }}
                      </div>
                      <div class="vod__category">
                        {{ item.stream_category_name }}
                      </div>
                      <div class="vod__date">
                        {{ getAgoClip(item.created) }} &bull;
                      </div>
                      <span class="vod__clipper" style="">
                        Clipped by: {{ item.clipper.display_name }}
                      </span>

                      <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :href="item.strm"
          >
            Watch
          </b-button> -->
                    </b-card></b-col
                  >
                </b-row>
              </section>
            </b-card-text>
          </b-tab>
          <b-tab title="Vods">
            <b-card-text>
              <section id="card-images" class="vodSection">
                <b-row v-if="channelVods">
                  <b-col
                    v-for="item in channelVods"
                    :key="item.xid"
                    md="5"
                    lg="4"
                    xl="3"
                    ><router-link :to="`/videos/${item.xid}`">
                      <b-card class="mb-3">
                        <div class="vod__image">
                          <progressive-img
                            width="100%"
                            v-b-popover.hover.bottom="''"
                            title=""
                            :src="`https://vods.brimecdn.com/${channelData.xid}/${item.xid}/thumbnail.png`"
                            fallback="https://assets.brimecdn.com/missing_thumbnail.png"
                          />
                          <div
                            class="vod__duration"
                            v-if="item.status == 'done'"
                          >
                            {{ getDuration(item.start_time, item.end_time) }}
                          </div>
                          <div
                            class="vod__duration_live"
                            v-if="item.status == 'recording'"
                          >
                            {{ getDuration(item.start_time, item.end_time) }}
                          </div>
                        </div>
                        <div id="vodTitle" class="vod__title">
                          {{ item.title }}
                        </div>
                        <div class="vod__category">CATEGORY</div>
                        <div class="vod__date">
                          {{ getAgo(item.start_time) }}
                        </div>
                        <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :href="item.strm"
          >
            Watch
          </b-button> -->
                      </b-card></router-link
                    ></b-col
                  >
                </b-row>
              </section>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <chat :channel="channelData.xid" v-if="channelData.xid" />
  </div>
</template>

<script>
/* eslint-disable */

import {
  VBPopover,
  BButton,
  BInputGroup,
  BFormInput,
  BForm,
  BAvatar,
  BModal,
  VBModal,
  BCardText,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BTabs,
  BTab,
  BRow,
  BCard,
  BCol,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import clipModule from "@/components/clipping/Clip.vue";
import VuePlyr from "vue-plyr";
import axios from "axios";
import Chat from "../../../components/chat/chat.vue";
import "vue-plyr/dist/vue-plyr.css";
import mqtt from "mqtt";
import Hls from "hls.js";

export default {
  directives: {
    "b-popover": VBPopover,
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BSpinner,
    BCard,
    BRow,
    BButton,
    BModal,
    BCardText,
    BInputGroup,
    BFormInput,
    BForm,
    BAvatar,
    Chat,
    BModal,
    clipModule,
    VBModal,
    BCardText,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BTabs,
    BTab,
    BCol,
    VuePlyr,
  },
  data() {
    return {
      lowLatencyMode: null,
      raidCount: 0,
      streamURL: "",
      userChannel: { stream: { live: false } },
      donationIntegration: {},
      streamlabs_donate: false,
      streamlabs_username: "",
      channelVods: {},
      offline_image: "",
      verified: false,
      isChannelOwner: false,
      isSubscribed: false,
      following: true,
      monetized: false,
      live: false,
      followers: 0,
      viewerCount: 0,
      countTerm: "viewers",
      viewCounter: null,
      clipModalSize: "lg",
      COUNT_DELETEME: 0,
      textInput: "",
      autoCompPlaceholder: "",
      suggestions: [],
      channel: { name: "geeken" },
      channelData: {
        channel: { slug: "" },
        owner: {},
        stream: { category_name: "" },
      },
      plyrOptions: {
        debug: false,
        invertTime: false,
        toggleInvert: false,
        // ratio: '16:9',
        controls: [
          // this.live ? 'play-large' : '', // The large play button in the center
          // 'play-large': '',
          // 'rewind',
          "play",
          // 'fast-forward',
          // 'progress',
          "mute",
          "volume",
          "current-time",
          "settings",
          "pip",
          "airplay",
          "fullscreen",
        ],
        // settings: ["quality", "captions"],
        quality: {
          default: "1080",
          options: ["1080"],
          forced: true,
          onChange: (e) => this.changeQuality(e),
        },
      },
    };
  },
  watch: {
    live: function (newValue, oldValue) {
      this.playbackHandler(newValue, oldValue);
    },
  },
  async created() {
    const channelLookup = await this.channelLookupBySlug(
      window.location.pathname.substring(1).toLowerCase()
    );
    this.channelData = channelLookup;
    if (this.channelData.stream.live == true) {
      this.offline_image =
        "https://assets.brimecdn.com/stream_loading.gif";
      await this.loadTransportScripts();
      this.lowLatencyMode =
        localStorage.getItem("brime_low_latency_mode") !== "false";
    } else {
      this.lowLatencyMode =
        localStorage.getItem("brime_low_latency_mode") !== "false";
      await this.loadTransportScripts();
      this.updatePlayerTransport();
    }
    if (this.channelData && !this.live) {
      if (this.channelData.offline_image) {
        this.offline_image = `https://assets.brimecdn.com/cdn-cgi/image/width=1920,quality=100/channels/${
          this.channelData.xid
        }/offline_image`;
      } else {
        this.offline_image =
          `https://assets.brimecdn.com/cdn-cgi/image/width=1920,quality=100/channels/${
          this.channelData.xid
        }/offline_image`;
      }
    }
    if (this.channelData.slug === "nasa") {
      this.channelData.slug = "NASA";
      document.getElementById("channelName").style["font-family"] =
        "nasalization-rg";
    }
    this.live = this.channelData.stream.live;
    setInterval(this.liveCheck, 10000);
    this.streamURL = `live.brimecdn.com/live/${this.channelData.xid}`;
    this.channelClips = await this.channelClips(channelLookup.xid);
    this.channelVods = await this.getVods(channelLookup.xid);
    // this.plyrOptions.quality.default = this.channelData.live_data.transcoded;
    // this.plyrOptions.quality.options = this.channelData.live_data.transcoded;

    if (this.channelData.monetization == true) {
      this.monetized = true;
    }
    this.followers = this.channelData.followers

    if (this.$auth.user) {
      this.following = await this.userFollowCheck(this.channelData.xid);
      this.isChannelOwner = (this.channelData.owner.xid == this.$auth.user.sub.replace("auth0|", ""));
    }

    // brimePlayer.setCallbacks({
    //         // onConnectionStarted: onConnectionStarted,
    //         // onConnectionEstablished:  this.onConnectionEstablished(),
    //         // onPlay: ,
    //         // onPause: onPause,
    //         // onVolumeSet: onVolumeSet,
    //         // onError: this.onError,
    //         // onConnectionClosed: onConnectionClosed,
    //         // onChangeRendition: onChangeRendition,
    //         // onChangeRenditionComplete: onChangeRenditionComplete,
    //         // onLatencyAdjustSeek: onLatencyAdjustSeek,
    //         // onLowBuffer: this.onLowBuffer
    //       });
    // }
  },
  async mounted() {
    // Now it's time for the player.
    const channelLookup = await this.channelLookupBySlug(
      window.location.pathname.substring(1).toLowerCase()
    );
    const channelData = channelLookup;
    document.title = `${channelLookup.display_name} | Brime`;
    document.body.classList.add("channel__page");
    // let donateLookup = await this.donateCheck(channelData.slug);
    // this.streamlabs_donate = donateLookup.enabled;
    // this.streamlabs_username = donateLookup.streamlabs_username;
    const donationSettings = await this.getChannelDonate(
      channelData.xid
    );
    this.donationIntegration = donationSettings;
    this.viewCounter = setInterval(async () => {
      this.viewerCount = await this.getViewercount(channelData.xid);
      this.countTerm = this.viewerCount > 1 ? "viewers" : "viewer";
    }, 5000);
    this.banCheck();
    var el = document.getElementById("localTranscript");
      el.remove();
    if(this.live){
    const plyrSettingsEl = document.getElementsByClassName("plyr__menu")[0];
    plyrSettingsEl.removeAttribute("hidden");
    }
  },
  methods: {
    onPlayerError(error) {
      console.log(error);
    },
    async playbackHandler(nv, ov) {
      if (nv == true) {
        this.updatePlayerTransport();
        setTimeout(async () => {
          console.log("loading player");
          // this.brimePlayer.setStreamURL(`wss://live.brimecdn.com/live/${this.channelData.channel.legacy_id}`)
        }, 2000);
      } else if (nv == false) {
        clearInterval(this.offlinePoller);
        this.player.poster = `https://content.brimecdn.com/live/${this.channelData.channel.legacy_id}/thumbnail.jpg`;
        this.brimePlayer.destroy();
        // this.player.destroy()
      }
    },
    async liveCheck() {
      const channelLookup = await this.channelLookupBySlug(
        window.location.pathname.substring(1).toLowerCase()
      );
      this.live = channelLookup.stream.live;
    },
    async openRaidModal() {
      this.$refs["raidModal"].show();
      const user = this.$auth.user;
      const sourceChannel = await this.channelLookupBySlug(user.nickname);
      this.raidCount = await this.getViewercount(sourceChannel.channel.xid);
    },
    sendRaid() {
      this.raidChannel(this.channelData.slug);
    },
    initCaptions() {
      const client = mqtt.connect("wss://chat-us.brime.tv/ws");
      class Transcript {
        constructor() {
          /** @type {Map<number, {words: string, is_final: boolean}>} */
          this.chunks = new Map();
        }

        /** @argument {any} jsonFromServer */
        addServerAnswer(jsonFromServer) {
          const words =
            JSON.parse(jsonFromServer).channel.alternatives[0].transcript;
          if (words !== "") {
            this.chunks.set(jsonFromServer.start, {
              words,
              // if "is_final" is true, we will never have future updates for this
              // audio chunk.
              is_final: jsonFromServer.is_final,
            });
          }
        }

        /** @returns {HTMLElement} */
        toHtml() {
          const divNode = document.createElement("div");
          divNode.className = "transcript-text";
          [...this.chunks.entries()]
            .sort((entryA, entryB) => entryA[0] - entryB[0])
            .forEach((entry) => {
              const spanNode = document.createElement("span");
              spanNode.innerText = entry[1].words;
              spanNode.className = entry[1].is_final ? "final" : "interim";
              divNode.appendChild(spanNode);
              divNode.appendChild(document.createTextNode(" "));
            });

          return divNode;
        }
      }
      const localTranscript = new Transcript();
      const remoteTranscript = new Transcript();
      client.on("connect", function () {
        client.subscribe("channel/cNhuxD2vE5v3os11OTt9/captions");
      });
      const localTranscriptNode = document.querySelector("#localTranscript");
      client.on("message", function (topic, message) {
        // message is Buffer
        const msg = message.toString();
        localTranscript.addServerAnswer(msg);
        localTranscriptNode.innerHTML = "";
        localTranscriptNode.appendChild(localTranscript.toHtml());
      });
    },
    onConnectionStarted(url) {
      console.log("onConnectionStarted: ", url);
    },
    onConnectionEstablished(streams) {
      addLog("onConnectionEstablished, streams:", JSON.stringify(streams));
      var renditions = this.brimePlayer.getRenditions();
      var streams = this.brimePlayer.getStreams();
      console.log("onConnectionEstablished, renditions:", renditions);
      console.log("onConnectionEstablished, streams:", streams);
    },
    donate(platform) {
      if (platform == "streamlabs") {
        window.open(
          `https://streamlabs.com/${this.donationIntegration.username}/tip`,
          "_blank"
        );
      } else if (platform == "kofi") {
        window.open(
          `https://ko-fi.com/${this.donationIntegration.username}/?hidefeed=false&widget=true&embed=true&preview=true' style='border:none;width:100%;padding:4px;background:#f9f9f9;`,
          "_blank"
        );
      } else if (platform == "streamelements") {
        window.open(
          `https://streamelements.com/${this.donationIntegration.username}/tip`,
          "_blank"
        );
      }
    },
    openClip(xid) {
      let url = `/clip/${xid}`;
      window.open(url, "_blank").focus();
    },
    // Dates and Times
    getDate(clipEpoch) {
      const d = new Date(clipEpoch);
      return d.toLocaleString();
    },
    getDuration(start, end) {
      if (end == null) return "LIVE";
      return new Date(end - start).toISOString().substr(11, 8);
    },
    getAgo(start) {
      let ago = Math.floor((new Date().getTime() - start) / 1000 / 60);
      if (ago < 60) {
        return `${ago}min ago`;
      }
      ago = Math.floor(ago / 60);
      if (ago < 24) {
        return `${ago}h ago`;
      }
      return `${Math.floor(ago / 24)} Days ago`;
    },
    getDurationClip(duation) {
      const timeStamp = new Date(duation * 1000).toISOString().substr(11, 8);
      return timeStamp.substr(3);
    },
    getAgoClip(start) {
      const secondsSinceEpoch = Math.floor(new Date().getTime());
      let ago = Math.floor((secondsSinceEpoch / 1000 - start / 1000) / 60);
      if (ago < 60) {
        return `${ago}min ago`;
      }
      ago = Math.floor(ago / 60);
      if (ago < 24) {
        return `${ago}h ago`;
      }
      return `${Math.floor(ago / 24)} Days ago`;
    },
    async banCheck() {
      if (this.channelData.banned == true) {
        clearInterval(this.viewCounter);
        document.title = "Brime";
        this.$router.push(`/banned/${this.channelData.slug}`);
      }
    },
    clipModalHideEvent() {
      this.player.muted = false;
    },
    openClipper() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.player.muted = true;
      this.$refs["clip-modal"].show();
    },
    setLowLatencyMode(enabled) {
      localStorage.setItem("brime_low_latency_mode", enabled);
      this.lowLatencyMode = enabled;
      // console.log('Low latency mode switched to', enabled, ', switching transports.')
      this.updatePlayerTransport();
      // The video element's source will change, so it won't auto play.
      document.querySelector("#stream-video").play();
    },
    killActiveTransport() {
      if (this.brimePlayer) this.brimePlayer.destroy();
      if (this.hls) this.hls.destroy();
    },
    async loadTransportScripts() {
      // const promise1 = new Promise(resolve => {
      //   const script = document.createElement('script')
      //   script.src = 'https://cdn.jsdelivr.net/npm/hls.js@latest'
      //   script.addEventListener('load', resolve)
      //   document.head.append(script)
      // })
      const promise2 = new Promise(resolve => {
        const script = document.createElement('script')
        script.src = 'https://cdn.jsdelivr.net/npm/plyr@3'
        script.addEventListener('load', resolve)
        document.head.append(script)
      })
      await Promise.all([promise2])
    },
    async updatePlayerTransport() {
      const videoElement = document.querySelector("#stream-video");
      this.killActiveTransport();
      // Hide the clip button
      // eslint-disable-next-line
      document.querySelector("#clipItButton")?.setAttribute("hidden", true);
      if (
        this.live &&
        this.lowLatencyMode &&
        window.brimePlayer.isMediaSourceSupported()
      ) {
        // SLDP check.
        this.brimeLLSuppported = true;
        const poster = this.live
        ? ``
        : `https://assets.brimecdn.com/live/${this.channelData.channel.xid}/offline_image`;
        this.brimePlayer = window.brimePlayer.init({
          container: "stream-player",
          video_element: "stream-video",
          stream_url: `wss://${this.streamURL}`,
          splash_screen: this.offline_image,
          autoplay: true,
          muted: false,
          offset: 2,
          buffering: 900,
          reconnects: 20,
          adaptive_bitrate: false,
          key_frame_alignment: true,
          height: "parent",
          width: "parent",
          initial_resolution: `1080p`,
          // key_frame_alignment: true,
        });
        const qualityCallback = (quality) => {
          console.log(quality);
          this.brimePlayer.changeRendition(`${quality}p`);
        };
        this.initPlyr(qualityCallback);
        this.player.on("pause", () => {
          this.brimePlayer.pause();
        });
        this.player.on("play", () => {
          this.brimePlayer.play();
        });
        this.player.poster = this.offline_image;
        this.brimePlayer.setCallbacks({
          // onConnectionStarted: this.onConnectionStarted,
          // onConnectionEstablished: this.onConnectionEstablished,
          // onPlay: onPlay,
          // onPause: onPause,
          // onVolumeSet: onVolumeSet,
          onError: this.onPlayerError,
          // onConnectionClosed: onConnectionClosed,
          // onChangeRendition: onChangeRendition,
          // onChangeRenditionComplete: onChangeRenditionComplete,
          // onLatencyAdjustSeek: onLatencyAdjustSeek,
          // onLowBuffer: onLowBuffer
        });
        if(this.live) this.player.muted = false
      } else if (this.live) {
        this.brimeLLSuppported = false;
        const source = `https://${this.streamURL}/playlist.m3u8`;
        if (window.Hls.isSupported()) {
          this.hls = new Hls;
          const hlsLoadPromise = new Promise((resolve) => {
            this.hls.on(window.Hls.Events.MANIFEST_PARSED, resolve);
          });
          this.hls.loadSource(source);
          this.hls.attachMedia(videoElement);
          await hlsLoadPromise;
          const changeQuality = (quality) => {
            // console.log(this.hls.levels)
            this.hls.levels.forEach((level, levelIndex) => {
              if (level.height === quality) {
                // console.log('Found quality match with ', quality)
                this.hls.currentLevel = levelIndex;
              }
            });
          };
          this.initPlyr(changeQuality);
        } else if (videoElement.canPlayType("application/vnd.apple.mpegurl")) {
          // Very edgecase-y.
          videoElement.src = source;
          // Unhide the clip button, since this is most-likely
          // a mobile enviroment that will hide the player and
          // show a platform-specific one.
          // eslint-disable-next-line
          document.querySelector("#clipItButton")?.removeAttribute("hidden");
          this.initPlyr(() => {});
        }
      }
      if(this.live){
      this.player.muted = false;
      }
    },
    initPlyr(qualityCallback) {
      if (!this.player) {
        this.player = new Plyr('#stream-video', this.plyrOptions);
        this.player.muted = false;
        const menusElement = document.querySelector(
          ".channel__column .plyr__controls .plyr__menu__container div"
        );
        const settingsHomeElement =
          menusElement.firstElementChild.firstElementChild;
        const toggleLowLatencyButton = document.createElement("button");
        toggleLowLatencyButton.innerHTML =
          '<span>Low Latency<span class="plyr__menu__value">Enabled</span></span>';
        toggleLowLatencyButton.classList =
          "plyr__control plyr__control--forward";
        toggleLowLatencyButton.type = "button";
        toggleLowLatencyButton.setAttribute("role", "menuitem");
        toggleLowLatencyButton.setAttribute("data-plyr", "settings");
        toggleLowLatencyButton.setAttribute("aria-haspopup", true);
        settingsHomeElement.appendChild(toggleLowLatencyButton);
        const toggleLowLatencyOpt = document.createElement("div");
        toggleLowLatencyOpt.innerHTML = `
          <button type="button" class="plyr__control plyr__control--back">
            <span aria-hidden="true">Low Latency Mode</span>
            <span class="plyr__sr-only">Go back to previous menu</span>
          </button>
          <div role="menu">
            <button data-plyr="lowlatency" type="button" role="menuitemradio" class="plyr__control" aria-checked="false" value="Enabled"><span>Enabled</span></button>
            <button data-plyr="lowlatency" type="button" role="menuitemradio" class="plyr__control" aria-checked="false" value="Disabled"><span>Disabled</span></button>
          </div>
        `;
        toggleLowLatencyOpt.id = "plyr-settings-lowlatency";
        toggleLowLatencyOpt.setAttribute("hidden", true);
        menusElement.appendChild(toggleLowLatencyOpt);
        const menuValue =
          toggleLowLatencyButton.querySelector(".plyr__menu__value");
        const lowLatencyEnable =
          toggleLowLatencyOpt.querySelector("[value='Enabled'");
        const lowLatencyDisable =
          toggleLowLatencyOpt.querySelector("[value='Disabled'");
        const updateCheckedButtons = () => {
          lowLatencyEnable.setAttribute("aria-checked", this.lowLatencyMode);
          lowLatencyDisable.setAttribute("aria-checked", !this.lowLatencyMode);
          menuValue.innerText = this.lowLatencyMode ? "Enabled" : "Disabled";
        };
        const navigateToHomeSettings = () => {
          settingsHomeElement.removeAttribute("hidden");
          toggleLowLatencyOpt.setAttribute("hidden", true);
        };
        // Go back to the home settings
        toggleLowLatencyOpt
          .querySelector(".plyr__control--back")
          .addEventListener("click", navigateToHomeSettings);
        // Radio buttons
        lowLatencyEnable.addEventListener("click", () => {
          this.setLowLatencyMode(true);
          updateCheckedButtons();
          navigateToHomeSettings();
        });
        lowLatencyDisable.addEventListener("click", () => {
          this.setLowLatencyMode(false);
          updateCheckedButtons();
          navigateToHomeSettings();
        });
        // Pop the menu out on click
        toggleLowLatencyButton.addEventListener("click", () => {
          toggleLowLatencyOpt.removeAttribute("hidden");
          settingsHomeElement.setAttribute("hidden", true);
        });
        updateCheckedButtons(); // Show the current value.
        const controlsMenu = document.querySelector(
          ".channel__column .plyr__controls"
        );
        if (this.live) {
          // Add the clip button to the main button list
          const clipButton = document.createElement("button");
          clipButton.classList = "plyr__controls__item plyr__control";
          clipButton.setAttribute("type", "button");
          clipButton.setAttribute("data-plyr", "clip");
          clipButton.innerHTML = `
            <svg style="fill: none !important;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-scissors"><circle cx="6" cy="6" r="3"></circle><circle cx="6" cy="18" r="3"></circle><line x1="20" y1="4" x2="8.12" y2="15.88"></line><line x1="14.47" y1="14.48" x2="20" y2="20"></line><line x1="8.12" y1="8.12" x2="12" y2="12"></line></svg>
            <span class="plyr__sr-only">Clip</span>
          `;
          clipButton.addEventListener("click", () => {
            // Click the button (hidden above)
            this.openClipper();
          });
          controlsMenu.insertBefore(
            clipButton,
            controlsMenu.querySelector("[data-plyr='pip']")
          );
          if (this.musicData && this.musicData.state === "MUSIC_PLAYING") {
            // Add Music Button
            const musicButton = document.createElement("button");
            musicButton.id = "musicButton";
            musicButton.classList = "plyr__controls__item plyr__control";
            musicButton.setAttribute("type", "button");
            musicButton.setAttribute("data-plyr", "clip");
            musicButton.innerHTML = `
            <svg style="fill: none !important; display: inline !important;" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-music"><path d="M9 18V5l12-2v13"></path><circle cx="6" cy="18" r="3"></circle><circle cx="18" cy="16" r="3"></circle></svg> ${this.musicData.metadata.music[0].title} - ${this.musicData.metadata.music[0].artists[0].name}
            <span class="plyr__sr-only">Clip</span>
          `;
            musicButton.addEventListener("click", () => {
              // Click the button (hidden above)
              window.open(
                `https://open.spotify.com/track/${this.musicData.metadata.music[0].external_metadata.spotify.track.id}`,
                "_blank"
              );
            });
            controlsMenu.insertBefore(
              musicButton,
              controlsMenu.querySelector("[data-plyr='pip']")
            );
            // this.updateMusicWidget()
          }
        }
        // Finally, click play.
        if (this.live) {
          this.player.play();
        }
      }
    },
    // Chargebee, FeelsBrimeProMan
    async subscribe() {
      const token = await this.$auth.getTokenSilently();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      window.cbInstance.openCheckout({
        hostedPage: () => {
          const messageBody = {
            plan_id: "tier-1",
            channel_xid: this.channelData.xid,
          };
          return axios
            .post(
              `https://api.brime.tv/v1/subs/generate_checkout`,
              messageBody,
              config
            )
            .then((response) => response.data.hosted_page);
        },
        success: (hostedPageId) => {
          this.isSubscribed = true;
        },
      });
    },
    async unfollow() {
      let result = await this.unfollowChannel(this.channelData.xid);
      if (result.success) {
        this.following = false;
      }
    },
    async follow() {
      let result = await this.followChannel(this.channelData.xid);
      if (result.success) {
        this.following = true;
      }
    },
    clipit() {},
    onConnectionEstablished() {
      console.log("connection established");
      setTimeout(() => {}, 1000);
    },
    onError(err) {},
    onLowBuffer() {
      console.log("Low Buffer");
    },
    changeQuality(quality) {
      if (this.brimePlayer) {
        this.brimePlayer.changeRendition(quality + "p");
      }
    },
    clearPlaceholder() {
      this.autoCompPlaceholder = "";
    },
    fillAutocomplete() {
      this.textInput += this.autoCompPlaceholder;
      this.autoCompPlaceholder = "";
    },
    autoSuggest() {
      const input = this.textInput;
      this.autoCompPlaceholder = "";
      const self = this;

      if (input.includes("@")) {
        const index = input.indexOf("@");
        if (input[index + 1]) {
          const inputToAutocomplete = input.substring(index + 1);
          let flag = false;
          // eslint-disable-next-line no-unused-vars, no-shadow
          this.suggestions.forEach((suggestion, index) => {
            if (flag) return;
            if (suggestion.indexOf(inputToAutocomplete) === 0) {
              self.autoCompPlaceholder += suggestion.substring(
                inputToAutocomplete.length
              );
              flag = true;
            }
          });
        }
      }
    },
    async createConnection() {},
  },
  destroyed() {
    document.body.classList.remove("channel__page");
    clearInterval(this.viewCounter);
    if(this.brimePlayer){
    this.brimePlayer.destroy()
    }
    this.player.destroy();
    document.title = "Brime";
  },
};
</script>

<style>
  .dark-layout .modal .modal-header .close {
    display: none;
  }
  #modal-lg___BV_modal_content_{
    box-shadow: 0px 0px 30px rgba(115,103,240,1);
    background-color: unset;
    border-radius: 20px;
  }
  #modal-lg___BV_modal_header_{
    border-radius: 20px 20px 0px 0px;
  }
  #modal-lg___BV_modal_body_{
    border-radius: 0px 0px 20px 20px;
    background-color: unset;
  } 
/* .interim {
  background-color: #4f6278;
  border-radius: 5px;
}

.final,
.interim {
  padding: 1px;
}

.transcript {
  text-align: center;
  color: white;
  font-size: x-large;
  z-index:5;
  position: relative;
  padding-right: 20vw;
  margin-top: -30px;
}

#localTranscript {

} */

.vod__title {
  margin-top: 0.5rem;
  font-weight: bolder;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.vod__image {
  position: relative;
  opacity: 1;
  transition: opacity 0.2s;
}
.vod__duration {
  z-index: 1;
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 0px 6px;
  background: #7367f0;
  color: white;
  border-radius: 3px;
  font-size: 0.9rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.vod__duration_live {
  z-index: 1;
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 0px 6px;
  background: #cc0f0f;
  color: white;
  border-radius: 3px;
  font-size: 0.9rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.vod__category,
.vod__date {
  font-size: 1em;
  color: #fff;
}
.tabSection {
  margin-top: 8vh;
  max-width: 78%;
}
.vodSection {
  margin-top: 5vh;
}
.btn-outline-primary {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.subscribe {
  border-radius: 5px !important;
}
.btn-icon {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.newFollowButton {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.dropdown-toggle {
  margin-left: 10px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  background-color: unset !important;
  border-color: #7367f0 !important;
  border: 5px !important;
}
.actionButtons {
  padding-top: 0.25rem;
  right: calc(21%) !important;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
body.channel__page .app-content {
  padding-top: var(--headerHeight) !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.channelData {
  font-size: 14px !important;
  margin-top: -5px;
  margin-left: 1rem !important;
}
.streamCategory {
  display: block;
}
.viewcount {
  font-size: 12px !important;
  color: #ff8280 !important;
}
.row {
  margin-left: 0px !important;
}
.channel__column > .plyr {
  width: 100%;
}
.plyr__poster {
  background-size: contain;
}
.plyr__controls {
  max-height: 80vh !important;
}
.dark-layout .nav-tabs .nav-item .nav-link.active {
  background-color: unset !important;
}
.plyr {
  --plyr-color-main: linear-gradient(90deg, #833ab4, #fd1d1d) !important;
  font-family: "Roboto", sans-serif;
}
.loadingClip {
  width: 100%;
}
.clipVideo {
  width: 100%;
}
.clipButton {
  margin-top: 5px;
  margin-right: 30px;
}
.subButton {
  margin-top: 5px;
  margin-right: 0px;
}
.followers {
  padding-left: 10px;
  font-size: 14px;
}
.streamTitle {
  padding-top: 5px;
  font-size: 14px;
}
.streamCategory {
  margin-top: -25px;
  margin-left: 55px;
  font-size: 14px;
}

/* Small screens */
@media all and (max-width: 767px) {
  .hide-on-mobile {
    display: none;
  }
  .channelData {
    font-size: 14px !important;
    margin-top: 7px !important;
    margin-left: 0.5rem !important;
    padding-bottom: 5px;
  }
  #channelName {
    font-size: 10px !important;
  }
  .followCount {
    display: none;
  }
  .actionButtons {
    margin-top: -75px !important;
    right: -15px !important;
    position: fixed;
  }
  #channel {
    font-size: 14px !important;
    margin-top: 15px !important;
    margin-left: 2px !important;
  }
  .viewcount {
    font-size: 12px !important;
  }
  .streamCategory {
    margin-top: 7px !important;
    margin-left: 0 !important;
  }
  #followersText {
    display: none;
  }
}
/* Medium and large screens */
@media all and (min-width: 768px) {
  #stream-video {
    /* override other styles to make responsive */
    width: 100% !important;
    min-height: 84vh !important;
  }
}
</style>
<style scoped>
.card:hover {
  transform: translateY(-3px);
  box-shadow: 0px 0px 20px rgba(115, 103, 240, 1);
}

.card img {
  transition: all 0.2s;
}
.card {
  box-shadow: none;
  padding: 0.5rem;
}
.vod__category {
  color: #7367f0 !important;
}
.card-body {
  padding: unset !important;
}
.card {
  background-color: unset !important;
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-4px);
}

.card:hover .vod__image {
  opacity: 1;
}

.vod__title {
  margin-top: 0.5rem;
  font-weight: bolder;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vod__image {
  position: relative;
  opacity: 1;
  transition: opacity 0.2s;
}

.vod__duration {
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 0px 6px;
  background: #7367f0;
  color: white;
  border-radius: 3px;
  font-size: 0.9rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.vodSection {
  margin-top: 5vh;
}
.vod__date {
  vertical-align: middle;
  display: inline-block;
  font-size: 1em;
  color: #fff;
  line-height: 1em;
}
.vod__clipper {
  display: inline;
  font-size: 0.9em !important;
  color: #b4b7bd;
  line-height: 1em;
}
#stream-video {
  /* override other styles to make responsive */
  width: 100% !important;
}
.disabled {
  margin-left: 0px !important;
}
.actionButtons {
  margin-top: -50px;
  margin-right: 20px;
}
.creatorMenu {
  margin-top: 10px;
}
#doge-canvas {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 9001;
}
.disable-scrollbars::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
html {
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
#channel {
  margin-top: 15px;
  top: 0;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  vertical-align: middle;
}
#channel img {
  border-radius: 50%;
  vertical-align: middle;
}
.row {
  margin-top: 0;
}
.channel__column {
  flex-wrap: nowrap;
}

#player {
  flex-shrink: 0;
}
.content-body {
  height: 100% !important;
}

.channel__column {
  width: calc(100% - max(20vw, 250px));
}

@media screen and (max-width: 768px) {
  .channel__column {
    width: 100%;
    flex-shrink: 0;
  }

  .channel__page {
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--headerHeight));
  }

  .content {
    padding: 0px;
  }

  #player {
    max-height: 80%;
  }
}
textarea {
  width: 200px;
  resize: none;
  overflow: hidden;
  font-size: 18px;
  height: 1.1em;
  padding: 2px;
}
</style>
